<template>
  <el-container class="container" ref="container" 
    style="display: flex;flex-direction:column;" >

    <div class="flex_row_left" style="flex-wrap:wrap;width:100%;margin-top:0px;background: #f5f7fa;padding-bottom:10px;">
       <el-input  autocomplete="off" :size="size" v-model="works.search" maxlength="50" clearable 
                @keyup.enter.native="EnterFun($event)" style="margin:10px 10px 0;flex-grow:1;max-width:300px;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
                  @click="getStationData(works.search,'')">查找</el-button>
                  
                <el-button type="success" slot="append" icon="el-icon-refresh" :size="size" plain 
                  style="width:50px;padding:10px 0px;" 
                  @click="getStationData(works.search,'')"></el-button>
                  </el-input>
              <el-select v-model="works.station" clearable :size="size" @change="getStationData('',works.station)"
                  placeholder="过滤站点" style="margin:10px 10px 0px;flex-grow:1;min-width:100px;max-width:150px;">
                <el-option
                  v-for="item in stationList"
                  :key="item.station"
                  :label="item.station"
                  :value="item.station">
                </el-option>
              </el-select>

              <el-button type="success" slot="prepend"  icon="el-icon-location-information" :size="size"  class="button" 
               style="margin:10px 10px 0;width:130px;"   @click="worksfind">送气工位置</el-button>
    </div>
    <!-- <div style="height:5px;background: #efeff4;"></div> -->
    <el-main ref="elmaincards"  style="justify-content: flex-start;display: flex;flex-direction:column;overflow-y: auto;"
      >
        <el-table :height="tableHeight" :size="size" ref="multipleTable" :data="worksList" highlight-current-row 
				v-loading="listLoading" element-loading-text="Loading..." :fit="true" 
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor" >
					<!--<el-table-column type="selection" width="55"></el-table-column>-->
					<!-- a.m_price,a.m_station,a.m_stats,a.m_caddress,a.m_cmemo,a.m_worker,
	a.m_scantime,convert(varchar(19),a.m_lasttime,120) m_lasttime,c.zdy2,anjian,b.m_identity,a.m_usecode,b.m_syzbh,a.m_lxmobile -->
<div slot="empty" >暂无数据</div>
					<el-table-column prop="px" label="序" width="38" align="center" :fit="true" show-overflow-tooltip>
            <div slot-scope="scope" style="text-align:left;font-size:12px;">
                {{scope.row.px}}</div>
          </el-table-column>
					<el-table-column prop="workname" label="送气工" width="90" min-width="60"  show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="workmobile" label="联系电话" width="110" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="station" label="站点" width="70"></el-table-column>
          <el-table-column prop="lng" label="经度" width="100"></el-table-column>
          <el-table-column prop="lat" label="纬度" width="100" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="ctype" label="定位类型" width="120" min-width="60" show-overflow-tooltip></el-table-column>

          <el-table-column prop="caddress" label="解析地址"  min-width="60" show-overflow-tooltip>
            <div slot-scope="scope" style="font-size:13px;">
                {{scope.row.caddress}}</div>
          
          </el-table-column>
          <el-table-column prop="ddatetime" label="时间"   min-width="60" show-overflow-tooltip></el-table-column>
          
        </el-table>
    </el-main>


    <el-drawer
      :visible.sync="showWorkTrack"
      direction="btt"  :wrapperClosable="true" :withHeader="false"
      custom-class="" title="送气工定位"  
      ref="drawerSelectWork" size="100%"
      >
      <!-- <div class="demo-drawer__content" > -->
      
    
    <div style="width:100%;height:100%;position:absolute;" v-loading="mpLoading" element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)">

      <baidu-map :center="{lng:121.67968256587015,lat:31.538427690383923}" :zoom="12" @ready="handler" 
      style="width:100%;height:100%;border-top: 1px solid #a1a3a7"></baidu-map>
    </div>
    <div style="padding-top:12px;padding-right:20px;position:absolute;width:100%;">
    <el-row ref="toolbar">
      <el-col :span1="24" >
        
          <div style="display:flex;align-items:center;float:right;">
            <el-switch v-model="works.isStation" active-color="#3961c0" inactive-color="#ff4949"
            
                :size1="size" @change="getLocationInfo" style="margin-right:10px;" >
            </el-switch>
            <el-select v-model="works.stationDT" clearable :size="size" @change="getLocationInfo"
                  placeholder="站点" style="width:100px;">
                <el-option
                  v-for="item in stationList"
                  :key="item.station"
                  :label="item.station"
                  :value="item.station">
                </el-option>
              </el-select>
          <el-button-group>
            <el-button type="primary" :size="size" icon="el-icon-close" @click="closeTab"></el-button>
          </el-button-group>
          </div>
        
      </el-col>
      </el-row>
      </div>
    </el-drawer>  
  </el-container>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return { 
      size: 'medium',//medium,small,mini,
      listLoading:false,
      mpLoading:false,//地图加载
      showWorkTrack:false,
      clearTime:true,
      works:{search:'',station:'',stationDT:'',isStation:true,},
      stationList:[],
      worksLocation:[],//送气工列表-过滤站点
      worksList:[],//地址数据，用于列表显示
      worksLocationList:[],//送气工列表-有GPS的2分钟数据
      custLocationList:[],
      tableHeight:300,
      maxrecid:"0",
      mytimers:[],
    }
  },
  computed: {
    tabBarWidth(){
      return this.$store.getters.GettabBarInfo("tabBarWidth");
    }
  },
  watch: {
    tabBarWidth(){
      this.initDivSize();
    }
  },
  methods: {
    getLocationInfo(){
      if (this.works.isStation){
        this.getWorkLocationInfo();
      }else{
        this.getCustLocationInfo();
      }
    },
    handler({BMap, map}){
      console.log("地图来了。。。")
      this.map=map
      this.map.centerAndZoom(new BMap.Point(121.64705610974767,31.611031028703508), 12)
      this.map.enableScrollWheelZoom(true);
      this.map.addControl(new BMap.GeolocationControl({anchor: BMAP_ANCHOR_BOTTOM_LEFT})) //创建一个地图定位控件
      this.map.addControl(new BMap.NavigationControl({ anchor: BMAP_ANCHOR_TOP_LEFT, type: BMAP_NAVIGATION_CONTROL_SMALL }))

      setTimeout(() => {
        this.getLocationInfo()
      }, 10);
    },
    getStationData(cs1,cs2){
      this.listLoading=true
      let data = {
        lx: "getstationdata",
        sqlone:this.$api.myFilterno(cs1),
        sqltwo:cs2,
        procext:"order"
      };
      this.$api.post("",data,(res) => {
        console.log("拿到数据。。。。",res)
          if (res["status"] == "1") {
            this.StaffID=res.StaffID;
            this.StaffName=res.StaffName;
            if (this.stationList.length==0){
              this.stationList=res.records;
            }
            this.worksList=res.recordc;
            
          }else
            this.$message.error(res["message"]);
          this.listLoading=false
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    getWorklocationlist(bj){
      if ((typeof this.map) == 'undefined') 
        return;
      if (!this.showWorkTrack) return
      let data = {
        lx: "getWorklocationlist",
        sqlone:this.works.stationDT,
        pageno:this.maxrecid,
        procext:"order"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.myclearTimer();
          
            if (res.records.length>0){
              let newarr=this.worksLocationList.concat(this.worksLocationList,res.records)
              this.worksLocationList=newarr
              this.maxrecid=res.records[res.records.length-1].recid
            }
            this.mytimers.push(setTimeout(() => {
                this.dispWorkLocation("work")
              }, 10));
            this.mytimers.push(setTimeout(() => {
              this.getWorklocationlist('0')
            }, (res.records.length>0 ? 10000 : 20000)));
          }else
            this.$message.error(res["message"]);
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    getCustLocationInfo(){
      //this.works.stationDT=this.works.station;
      let data = {
        lx: "getcustlocationinfo",
        sqlone:this.works.stationDT,
        procext:"order"
      };
      this.mpLoading=true
      this.showWorkTrack=true
      this.clearTime=true
      this.myclearTimer();
      this.map.clearOverlays();
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.$message.success(res["message"]);
            if (this.stationList.length==0){
              this.stationList=res.records;
            }
            this.custLocationList=res.recordc
            console.log("custLocationList.length",this.custLocationList.length)
            this.clearTime=false;
            this.mytimers.push(setTimeout(() => {
                this.dispWorkLocation("statcust")
              }, 10))
            this.mpLoading=false
          }else
            this.$message.error(res["message"]);
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    getWorkLocationInfo(){
      //this.works.stationDT=this.works.station;
      this.mpLoading=true
      let data = {
        lx: "getWorklocationinfo",
        sqlone:this.works.stationDT,
        procext:"order"
      };
      this.showWorkTrack=true
      this.clearTime=true
      this.myclearTimer();
      this.map.clearOverlays();
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            
            if (this.stationList.length==0){
              this.stationList=res.records;
            }
              this.worksLocation=res.recordc;
              this.worksLocationList=res.recordd
              this.maxrecid=res.maxrecid
            //console.log(this.worksLocation,this.worksLocationList)
            //this.worksList=res.recordc;
            this.clearTime=false;
            this.mpLoading=false
            this.mytimers.push(setTimeout(() => {
                this.dispWorkLocation("statwork")
              }, 10))
            this.mytimers.push(setTimeout(() => {
                this.getWorklocationlist('1')
              }, 20000))
          }else
            this.$message.error(res["message"]);
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    closeTab(){
      this.clearTime=true
      this.showWorkTrack=false
      this.myclearTimer()
    },
    worksfind(){
      this.showWorkTrack=true
      //this.getWorkLocationInfo()
    },
    dispWorkLocation(ctype){
      if (this.clearTime || !this.showWorkTrack) return
      if ((typeof this.map) == 'undefined')
        this.initMAP();
      if ((typeof this.map) == 'undefined') return;
      if ((typeof ctype) == 'undefined') ctype="";
      let iconWork2 = new BMap.Icon("http://www.yh59481717.com/images/map-6.png", new BMap.Size(44, 50));
      let iconWork = new BMap.Icon("http://www.yh59481717.com/images/map-4.png", new BMap.Size(44, 50));
      let iconStat = new BMap.Icon("http://www.yh59481717.com/images/map-2.png", new BMap.Size(44, 50));
      let label=null,marker =null
      if (ctype.indexOf("stat")>-1){
        let statlist=this.stationList.filter(e=>{
          return (this.works.stationDT=="" || e.station==this.works.stationDT)
        })
        //console.log(this.works.stationDT,statlist)
        statlist.forEach(s=>{
          let points =new BMap.Point(s.statXY.split("|")[0],s.statXY.split("|")[1]);
          label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+s.station+"</div>",{point:points,offset:new BMap.Size(-6,-20)});
          label.setStyle({background:"#bbfc9b",color:"#333",cursor:"pointer"});
          marker = new BMap.Marker(points, { enableDragging: true,icon:iconStat});
          marker.setLabel(label);
          this.map.addOverlay(marker);
        })
      }
      if (ctype.indexOf("cust")>-1){
        let st=this.works.stationDT
        this.stationList.forEach(s=>{
          let custlist=this.custLocationList.filter(e=>e.station==s.station && (st=="" || s.station==st))
          if (custlist.length>0){
              this.mytimers.push(setTimeout(() => {
                  this.showCustCollection(custlist);
                }, 10))
          }
        })
      }
      if (ctype.indexOf("work")>-1){
        let pointw=null,pointwp=null
        this.worksLocation.forEach((xx,index) => {
          pointwp=null;
          let works=[]
          //console.log(this.worksLocationList)
          if (this.worksLocationList.length>0){
            works=this.worksLocationList.filter((e)=>{
              return (e.workmobile==xx.workmobile && e.recid>xx.precid)
            })
          }
          
          if (works.length>0){
            //console.log(xx,works[0])
            console.log("works.length",works.length,xx.workname)
            if (xx.precid>works[0].recid){
              console.log("不可能的")
              xx.lng=works[0].lng;
              xx.lat=works[0].lat;
            }
            this.mytimers.push(setTimeout(() => {
              this.showworkpolyline(index,works);
            }, 10))
          }
          if ((typeof xx.marker) == 'undefined'){
            pointwp = new BMap.Point(xx.lng,xx.lat);
            label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+xx.workname+"</div>",
            {point:pointwp,offset:new BMap.Size(-6,-20)});
            label.setStyle({background:"#bbfc9b",color:"#333",cursor:"pointer"});
            marker = new BMap.Marker(pointwp, { enableDragging: true,icon:iconWork});
            marker.setLabel(label);
            this.map.addOverlay(marker);
            //marker.setAnimation(BMAP_ANIMATION_BOUNCE);//BMAP_ANIMATION_DROP
            xx.marker=marker;
          }
          

        })
      }
      if (1==0 && ctype.indexOf("work")>-1){
        let pointw=null,pointwp=null
        this.worksLocation.forEach((xx,index) => {
          pointwp=null;
          let works=[]
          //console.log(this.worksLocationList)
          if (this.worksLocationList.length>0){
            works=this.worksLocationList.filter((e)=>{
              return (e.workmobile==xx.workmobile && e.recid>xx.precid)
            })
          }
          
          if (works.length>0){
            //console.log(xx,works[0])
            console.log("works.length",works.length,xx.workname)
            if (xx.precid>works[0].recid){
              console.log("不可能的")
              xx.lng=works[0].lng;
              xx.lat=works[0].lat;
            }
            this.mytimers.push(setTimeout(() => {
              this.showworkpolyline(index,works);
            }, 10))
          }
            if ((typeof xx.marker) == 'undefined'){
              pointwp = new BMap.Point(xx.lng,xx.lat);
              label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+xx.workname+"</div>",
              {point:pointwp,offset:new BMap.Size(-6,-20)});
              label.setStyle({background:"#bbfc9b",color:"#333",cursor:"pointer"});
              marker = new BMap.Marker(pointwp, { enableDragging: true,icon:iconWork});
              marker.setLabel(label);
              this.map.addOverlay(marker);
              //marker.setAnimation(BMAP_ANIMATION_BOUNCE);//BMAP_ANIMATION_DROP
              xx.marker=marker;
            }
          

        })
      }
    },
    showworkpolyline(idx,works){
      //this.worksLocation[idx].lng
      if (this.clearTime || !this.showWorkTrack) return;
      //let this=this
      let color=['#eba010','#67c23a','#f56c6c','#107ff2','#ab987b','#a6a9b0','#a68340','#67c23a','#f56c6c','#107ff2','#ab987b','#a6a9b0']
      let pointwp=null;
      let linepath=null;
      let refrsh=0;//0不用更新，1有新point
      if ((typeof this.worksLocation[idx].polyline) != 'undefined'){
        linepath = this.worksLocation[idx].polyline.getPath()
      }else{
        linepath=[new BMap.Point(this.worksLocation[idx].lng,this.worksLocation[idx].lat)]
      }
      works.forEach(e=>{
        let pointwp=linepath[linepath.length-1]
        let pointw =new BMap.Point(e.lng,e.lat);
        if (this.map.getDistance(pointwp,pointw)>4){
          refrsh=1;
          linepath.push(pointw);
        }
        this.worksLocation[idx].lng=e.lng
        this.worksLocation[idx].lat=e.lat
        this.worksLocation[idx].precid=e.recid
      })
      if ((typeof this.worksLocation[idx].polyline)!='undefined'){
        if (refrsh==0) return
        this.worksLocation[idx].polyline.setPath(linepath)     
      }else{
          let polyline =new BMap.Polyline(linepath, {
          enableEditing: false,//是否启用线编辑，默认为false
          //enableClicking: true,//是否响应点击事件，默认为true
          //icons:[iconLine],
          //strokeStyle:'dashed',
          strokeWeight:'6',//折线的宽度，以像素为单位
          strokeOpacity: 0.9,//折线的透明度，取值范围0 - 1
          strokeColor:color[idx%10] //折线颜色
        });
        this.map.addOverlay(polyline);
      }
        let iconWork2 = new BMap.Icon("http://www.yh59481717.com/images/map-6.png", new BMap.Size(44, 50),{imageOffset:new BMap.Size(12, 0)});
        let iconWork = new BMap.Icon("http://www.yh59481717.com/images/map-4.png", new BMap.Size(44, 50),{imageOffset:new BMap.Size(12, 0)});
        let iconStat = new BMap.Icon("http://www.yh59481717.com/images/map-2.png", new BMap.Size(44, 50),{imageOffset:new BMap.Size(12, 0)});
        let pointlast=linepath[linepath.length-1];
        if ((typeof this.worksLocation[idx].marker) != 'undefined'){
          this.worksLocation[idx].marker.setPosition(pointlast)
        }else{
          let label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+
          this.worksLocation[idx].workname+"</div>",
          {point:pointlast,offset:new BMap.Size(-6,-20)});
          label.setStyle({background:color[idx%10],color:"#eee",cursor:"pointer"});
          let marker = new BMap.Marker(pointlast, { enableDragging: true,icon:iconWork2});
          marker.setLabel(label);
        
          this.map.addOverlay(marker);
          marker.setIcon(iconWork)
          this.worksLocation[idx].marker=marker;
        }
    },
    showworkpolylineold(idx,works){
      //this.worksLocation[idx].lng
      if (this.clearTime || !this.showWorkTrack) return;
      //let this=this
      let color=['#eba010','#67c23a','#f56c6c','#107ff2','#ab987b','#a6a9b0','#a68340','#67c23a','#f56c6c','#107ff2','#ab987b','#a6a9b0']
      let p1=this.worksLocation[idx].lng;
      let p2=this.worksLocation[idx].lat
      let pointwp=new BMap.Point(p1,p2);
      let workrow=works.shift();
      //console.log(idx,works.length,workrow)
      let pointw =new BMap.Point(workrow.lng,workrow.lat);
      //let pois = [new BMap.Point(xx.lng,xx.lat),point2];
      this.worksLocation[idx].precid=workrow.recid
      let js=works.length
      let ys=(3000/js)>500 ? 500 : (3000/js<100?100:3000/js)
      if (js>0){
        this.mytimers.push(setTimeout(() => {
          this.showworkpolyline(idx,works);
        }, ys));
      }
      if (this.map.getDistance(pointwp,pointw)>4){
        //console.log(idx,works.length,workrow)
        let iconWork2 = new BMap.Icon("http://www.yh59481717.com/images/map-6.png", new BMap.Size(44, 50),{imageOffset:new BMap.Size(12, 0)});
        let iconWork = new BMap.Icon("http://www.yh59481717.com/images/map-4.png", new BMap.Size(44, 50),{imageOffset:new BMap.Size(12, 0)});
        let iconStat = new BMap.Icon("http://www.yh59481717.com/images/map-2.png", new BMap.Size(44, 50),{imageOffset:new BMap.Size(12, 0)});
        
        
        let polyline =new BMap.Polyline([pointwp,pointw], {
          enableEditing: false,//是否启用线编辑，默认为false
          //enableClicking: true,//是否响应点击事件，默认为true
          //icons:[iconLine],
          //strokeStyle:'dashed',
          strokeWeight:'6',//折线的宽度，以像素为单位
          strokeOpacity: 0.9,//折线的透明度，取值范围0 - 1
          strokeColor:color[idx%10] //折线颜色
        });
        this.map.addOverlay(polyline);
        this.worksLocation[idx].precid=workrow.recid
        if ((typeof this.worksLocation[idx].marker) != 'undefined') 
          this.map.removeOverlay(this.worksLocation[idx].marker);
        this.worksLocation[idx].lng=workrow.lng;
        this.worksLocation[idx].lat=workrow.lat;
        //xx.recid=w.recid
        let label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+
        this.worksLocation[idx].station+"-"+this.worksLocation[idx].workname+"</div>",
        {point:pointw,offset:new BMap.Size(-6,-20)});
        label.setStyle({background:color[idx%10],color:"#eee",cursor:"pointer"});
        let marker = new BMap.Marker(pointw, { enableDragging: true,icon:iconWork2});
        marker.setLabel(label);
        // if (this.worksLocation[idx].workname==this.orderHead.m_worker){
        //   marker.setIcon(iconWork2)
        //   label.setStyle({background:"#a0cfff",color:"#333",cursor:"pointer"});
        // }
        this.map.addOverlay(marker);
        //marker.setAnimation(BMAP_ANIMATION_BOUNCE);
        marker.setIcon(iconWork)
        this.worksLocation[idx].marker=marker;
        //xx.precid=w.recid
        //console.log(idx,works.length,workrow.mobile,workrow.recid)
      }
        //console.log(idx,works.length,'<5不移动')

    },
    showCustCollection(custlist){
      let points=custlist.map(e=>{
        return new BMap.Point(e.lng,e.lat)
      })
      let colors=['rgba(221, 64, 195, 0.5)','rgba(90, 137, 247, 0.5)','rgba(96, 221, 34, 0.5)',
      'rgba(70, 249, 234, 0.5)','rgba(249, 188, 70, 0.5)']
        console.log(custlist.length)
        let options = {
            size: BMAP_POINT_SIZE_BIG,//SMALL,NORMAL,BIG,BIGGER,HUGE
            shape: BMAP_POINT_SHAPE_STAR,//CIRCLE,SQUARE,RHOMBUS,WATERDROP
            color: colors[Math.floor(Math.random()*5)] // '#d340c3'
        }
        let pointCollection = new BMap.PointCollection(points, options);  // 初始化PointCollection
        pointCollection.addEventListener('click', (e)=>{
          //alert('单击点的坐标为：' + e.point.lng + ',' + e.point.lat);  // 监听点击事件
          this.showCustinfo(e)
        });
        this.map.addOverlay(pointCollection); 
    },
    //alert('单击点的坐标为：' + e.point.lng + ',' + e.point.lat);  // 监听点击事件
    showCustinfo(e){
      console.log('单击点的坐标为：' + e.point.lng + ',' + e.point.lat)
      let idx=this.custLocationList.findIndex(f=>{
        return f.lng==e.point.lng && f.lat==e.point.lat})
      if (idx>-1){
        let cust=this.custLocationList[idx]
        this.$message({
          dangerouslyUseHTMLString: true,
          message: '<strong height=20px>'+cust.station+'—'+cust.Contact+' '+cust.Mobile+'</strong><hr>'+cust.CompanyAddr+''
        })
      }
    },
    initMAP1() {
      //console.log(this.map)
     
      if ((typeof this.map) == 'undefined'){
         this.map = new BaiduMap.Map('map-container', { enableMapClick: true,minZoom: 5,maxZoom: 20 });
      
      
       
      //loadBMap('8ktYGStGratzruLRGVenOKpM7gAn39d6')
      // 1、挂载地图
      //this.map = new BMap.Map('map-container', { enableMapClick: true,minZoom: 5,maxZoom: 20 })
      var point = new BMap.Point(121.67968256587015,31.538427690383923)
      this.map.centerAndZoom(new BMap.Point(121.64705610974767,31.611031028703508), 12)
      this.map.enableScrollWheelZoom(true);
      // 3、设置图像标注并绑定拖拽标注结束后事件
      //this.mk = new BMap.Marker(point, { enableDragging: false })
      //this.map.addOverlay(this.mk)
      // this.mk.addEventListener('dragend', function(e) {
      //   this.getAddrByPoint(e.point)
      // })
      //this.mk.setAnimation(BMAP_ANIMATION_BOUNCE);
      this.map.addControl(new BMap.GeolocationControl({anchor: BMAP_ANCHOR_BOTTOM_LEFT})) //创建一个地图定位控件

      // 4、添加（右上角）平移缩放控件
     this.map.addControl(new BMap.NavigationControl({ anchor: BMAP_ANCHOR_TOP_LEFT, type: BMAP_NAVIGATION_CONTROL_SMALL }))
      
      }
      setTimeout(() => {
        //this.getWorkLocationInfo();
      }, 0);
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #f0f0f0;'
        }
    },

    EnterFun(e){
      this.getStationData(this.works.search,'')
    },
    initDivSize() {
      this.isPc=this.$store.getters.GettabBarInfo("isPc");
      let divwidth=this.$store.getters.GettabBarInfo("tabBarWidth");
      if (this.isPc){
        this.pagelayout="total,sizes,prev,pager,next,jumper"
      }else{
        this.pagelayout="total,prev,pager,next"
      }
      //tabBarWidth=tabBarWidth
      //if (this.StaffID && this.isPc){

      //}
      if (!this.isPc || divwidth<950){
        this.divwidth=divwidth;
      }else{
        if (divwidth*0.8<950){
          this.divwidth=950;}
        else{this.divwidth=divwidth*0.8}
      }
      if (this.$refs.elmaincards){
        this.$nextTick(() => {
          //监听到事件后执行的业务逻辑
          
                     //console.log(element.offsetWidth,element.offsetHeight);       
                 
          
       
        let elmaincard = this.$refs.elmaincards.$el.offsetWidth*0.4
        let elmaincardh = this.$refs.container.$el.offsetHeight*0.9
        this.elmaincardh =elmaincardh;
        this.elmaincard = elmaincard<350?350:elmaincard;
        console.log("this.elmaincard",elmaincard,this.elmaincard,elmaincardh);
        });
      }
    },
    myclearTimer(){
      this.mytimers.forEach(e=>{
        if (e) clearTimeout(e)
      })
      this.mytimers=[];
    },
  },
  created() {
    console.log("aaaaa",10%10,11%10)
    this.getStationData('','')
  },
  mounted() {
    window.onresize = () => {
      //this.initDivSize();
    };
    this.mytimers.push(
        setTimeout(() => {
        //if (this.mytimer) clearTimeout(this.mytimer)
      //console.log("测试")
      }, 2000)
    )
    //this.myclearTimer();
    //console.log(this.mytimers)
  },
  beforeDestroy() {
    this.clearTime=true
    this.showWorkTrack=false;
    this.myclearTimer();
    if ((typeof this.map) != 'undefined'){
      //this.map.destroy();
      this.map=null;
    }
  },
}
</script>
<style src = "../../Styles/cover.css"></style>
<style src = "../../Styles/common.css"></style>
<style src = "../../Styles/iconfont/iconfont.css"></style>

<style>
.input-with-select .el-input-group__append{
    background-color: #fff !important;
  }
.container .el-main {
  padding: 8px 10px 10px 10px !important

}
</style>
<style scoped>
.myscrollbar::-webkit-scrollbar{
display:none;
}
.flex_row_left{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;     /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_row_right{
    display: flex;             
    justify-content: flex-end; 
    align-items: center; 
    flex-direction: row;
}
.flex_row{
    display: flex;             /*设置为flex布局*/
    justify-content: center;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_col{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: column;
}
.subtitle{
  font-size:16px;
  color:#222222;
  line-height:30px;
  padding-left:10px;
  /* padding-top:5px;
  padding-bottom:5px; */
}
.label0{
  width:50%;
}
.label1{
  width:15%;
  text-align: center;
}
.label2{
  width:35%;
  text-align: center;
}
.labe20{
  width:30%;
}
.labe21{
  width:45%;
  text-align: center;
}
.labe22{
  width:25%;
  text-align: center;
}
.container {
  width: 100%;
  height: 100%;
  /* background-color: #a4c7f5; */
}
.el-form-item {
    margin-bottom: 0px;
}
.el-dialog{
    min-width:320px !important;
}
.el-descriptions__header{
  margin-bottom:10px !important; 
}    
.my-label{
  width:20%;
  max-width:150px;
  min-width:80px;
}
.my-content{
  min-width:100px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.input1 {
  width: 200px;
}
.button {
  /* width: 100px; */
  padding-left: 10px;
  padding-right: 10px;
}
.el-card__body{
  padding:0  !important; 
}

  </style>

